@import "../../../static/scss/variables.scss";

.MuiButton-label {
  font-size: 12px;
}

.menuItem {
  width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.MuiButton-root {
  min-width: auto !important;
  height: 28px;
}
//currency

.overviewWrapper {
  position: absolute;
  left: 0px !important;
  top: 0px;
  right: 0px !important;
  padding-bottom: 20px;
}

.currency-hover-class li:hover {
  background-color: whitesmoke !important;
}

.overviewWrapper .title {
  font-size: 22px;
  font-weight: 500;
}

.overviewWrapper .rangePickr {
  width: 300px;
}

.chart .MuiPaper-root {
  width: 100% !important;
}

.overviewWrapper .spaceBetween {
  display: flex;
  justify-content: space-between;
}

.overviewWrapper .label {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  // border-bottom: 2px dotted $light-light-grey;
}

.overviewWrapper .label2 {
  font-size: 14px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.87);
}

.overviewWrapper .link {
  font-size: 13px;
  color: $primary;
}

.overviewWrapper .numericValue {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
}

.overviewWrapper .dash {
  font-weight: 500;
}

.overviewWrapper .visitor {
  font-size: 13px;
}

.overviewWrapper .graphTitle {
  font-weight: 400;
  color: #212b36;
  font-size: 12px;
}

.overviewWrapper .card {
  box-shadow: 1px 1px 3px $light-light-grey;
}

.overviewWrapper .head {
  position: fixed;
  z-index: 500;
  left: 0px !important;
  top: 0px;
  right: 0;
  padding: 5px 15px 5px 12px;
  background: $white;
  border-bottom: 1px solid $light-light-grey;
}

// .overviewWrapper tspan,
// .overviewWrapper .highcharts-point,
// .overviewWrapper .highcharts-graph,
.overviewWrapper .highcharts-legend,
.overviewWrapper .highcharts-credits {
  display: none !important;
}

.session {
  font-size: 14px;
  color: #6d7f8d;
}

.selectOption {
  margin-left: 10px;
  height: 20px;
  width: 200px;
}

.overviewWrapper .overviewBody {
  margin-top: 75px;
  padding: 0px 10px;
}

.overviewWrapper .selectBody {
  width: 200px;
  height: 20px;
}

.fadeIn {
  -webkit-animation: fadeIn 0.5s; /* Safari 4+ */
  -moz-animation: fadeIn 0.5s; /* Fx 5+ */
  -o-animation: fadeIn 0.5s; /* Opera 12+ */
  animation: fadeIn 0.5s; /* IE 10+, Fx 29+ */
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  // width: 34% !important;
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.highcharts-tooltip-box {
  fill: black;
  fill-opacity: 0.6;
  stroke-width: 0;
}

.highcharts-tooltip text {
  fill: white !important;
  text-shadow: 0 0 3px black;
}

.top-n-table {
  background-color: var(--p-surface, #fff);
  box-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
  outline: 0.1rem solid transparent;
  margin-top: -0.8rem;
}

// .text-center {
//   padding: 2rem;
// }

.no-data {
  padding: 2rem;
}

// .topBrandSelectBox {

//   .css-b8ldur-Input {
//    .select__input {
//      display:  none;
//    }
// }
// }

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  .top-n-table {
    border-radius: var(--p-border-radius-wide, 3px);
  }
}
