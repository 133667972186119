.selectWrapper {
  position: fixed;
  top: 25px;
  left: 40px;
  right: 19px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
}

.selectWrapper > div {
  margin-left: 25px;
  width: 200px;
}

.paginationWrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;
}
